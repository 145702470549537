.parallax-s{
    overflow: hidden;
    height: 500px;
    width: 100%;
}

[class*="features-"],
[class*="team-"],
[class*="projects-"],
[class*="pricing-"],
[class*="testimonials-"],
[class*="contactus-"]{
  padding: 80px 0;
}

@import 'sections/_accordion';
@import 'sections/_tables';
@import 'sections/_headers';
@import 'sections/_features';
@import 'sections/_blogs';
@import 'sections/_team';
@import 'sections/_projects';
@import 'sections/_pricing';
@import 'sections/_testimonials';
@import 'sections/_contactus';

@import 'sections/_social-subscribe-lines';
