/*
 * bootstrap-tagsinput v0.8.0
 *
 */

.bootstrap-tagsinput {
    display: inline-block;
    padding: 4px 6px;
    max-width: 100%;
    line-height: 22px;

    input{
        border: none;
        color: $dark-gray;
        box-shadow: none;
        outline: none;
        background-color: transparent;
        margin: 0;
        width: 74px;
        max-width: inherit;

        &:focus{
            border: none;
            box-shadow: none;
        }
    }

    &.form-control input::-moz-placeholder{
        color: #777;
        opacity: 1;
    }

    &.form-control input:-ms-input-placeholder,
    &.form-control input::-webkit-input-placeholder{
        color: #777;
    }

    .badge{
        cursor: pointer;
        margin: 5px 3px 5px 0;
        position: relative;
        padding: 3px 8px;
        border-radius: 12px;
        font-weight: 500;
        font-size: 0.75em;
        text-transform: uppercase;
        display: inline-block;
        line-height: 1.5em;
        padding-left: 0.8em;

        &:hover{
            padding-right: 22px;

            [data-role="remove"]{
                opacity: 1;
                padding-right: 4px;
            }
        }

        [data-role="remove"]{
            cursor: pointer;
            position: absolute;
            top: 3px;
            right: 0px;
            opacity: 0;
            background-color: transparent;
        }

        [data-role="remove"]{
            &:after{
                font-family: 'Nucleo';
                content: "\ea48";
                padding: 0px 2px;
            }
        }
    }


}
