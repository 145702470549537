.navbar .dropdown.show .dropdown-menu .dropdown-menu{
  display: none;
  height: 200px;
  overflow: auto;
  &.show{
    display: block;
  }
}
.dropdown-menu{
  i{
    margin-right: 5px;
    position: relative;
    top: 1px;
  }

  .tim-icons{
    margin-right: 10px;
    position: relative;
    top: 4px;
    font-size: 18px;
    margin-top: -5px;
    opacity: .5;
  }

  .dropdown-item{
    &.active,
    &:active{
      color: inherit;
    }
  }

  .dropup &{
    &:before{
      display: none;
    }

    &:after{
      display: inline-block;
      position: absolute;
      width: 0;
      height: 0;
      vertical-align: middle;
      content: "";
      top: auto;
      bottom: -5px;
      right: auto;
      left: 10px;
      color: $white;
      border-top: .4em solid;
      border-right: .4em solid transparent;
      border-left: .4em solid transparent;
    }

    &.dropdown-menu-right{
      &:after{
        right: 10px;
        left: auto;
      }
    }
  }

  .dropdown-item,
  .bootstrap-select &.inner li a{
    font-size: $font-size-sm;
    padding-top: .6rem;
    padding-bottom: .6rem;
    margin-top: 5px;
    @include transition($fast-transition-time, $transition-linear);

    &:hover,
    &:focus{
      background-color: $opacity-gray-3;
    }

    &.disabled,
    &:disabled{
      color: $default-opacity;

      &:hover,
      &:focus{
        background-color: transparent;
      }
    }
  }

  .dropup:not(.bootstrap-select) &,
  .bootstrap-select &:not(.inner),
  &.bootstrap-datetimepicker-widget{
    @include transform-translate-y-dropdown(-25px);
    visibility: hidden;
    display: block;
    @include opacity(0);
    top:100% !important;
  }

  .bootstrap-select.show &:not(.inner),
  &.bootstrap-datetimepicker-widget.bottom.open,
  &.bootstrap-datetimepicker-widget.top.open,
  .dropup.show:not(.bootstrap-select) &{
    @include opacity(1);
    visibility: visible;
    @include transform-translate-y-dropdown(0px);
  }

  .dropup:not(.bootstrap-select) &,
  &.bootstrap-datetimepicker-widget.top{
    @include transform-translate-y-dropdown(25px);
    bottom: 100% !important;
    top: auto !important;
  }

  .dropup.show:not(.bootstrap-select) &,
  &.bootstrap-datetimepicker-widget.top.open{
    @include transform-translate-y-dropdown(-1px);
  }
}
